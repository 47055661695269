<template>
   <div style="width:100%;height:100%;background-color:#F2EEEE;">
      <div style="width:100%;height:100%;background-color:#F2EEEE;"> 

         <v-container 
            style="max-width:2600px;"
            class="pa-0">

            <!-- Realtime Logs -->
            <v-row class="ma-0 pa-0">
               <v-col col="12" class="pa-4 pt-0">
                  <div style="font-size:18px;font-weight:500;line-height:1.8;color:#444444;">
                     Realtime Monitor:
                  </div>
                  <div  class="pa-2" 
                     style="background-color:#DDDDDD;color:#000000;overflow-y:auto;min-height:380px;max-height:380px;border: 1px solid #888888;border-radius: 4px;font-size:14px;">
                     <div v-for="(log, index) in logs" :key="index"
                        style="margin-bottom:2px;">
                        <span class="mr-4"><b>{{log.date}}</b></span>
                        <div v-html="log.content" style="display:inline-block;vertial-align:top;">
                        </div>
                     </div>
                  </div>
               </v-col>
            </v-row>
         </v-container>
      </div>
   </div>
</template>

<script>
export default {  
   data () {
      return {
         logs: [],
         loading: false,
         roles: ['equipments-manager', 'admin'],
         properties: [],
      }
   },
   sockets: {
      foundUhfCardInDoor: function (payload){
         // console.log('foundUhfCardInDoor')
         // console.log(payload)
         let items = payload.split(';')
         let uhfCode = ""
         if(items.length>0) uhfCode = items[0]
         // let data = JSON.parse(payload)
         this.logs.push({
            date: this.$moment().format("HH:mm:ss"),
            content: "[Trong kho] <b>" + uhfCode + "</b> <i>" + this.getNameByQRCode(uhfCode) + "</i>"
         })
         this.trimLog()
      },
      foundUhfCardOutDoor: function (payload){
         // console.log('foundUhfCardOutDoor')
         // console.log(payload)
         // let data = JSON.parse(payload)
         let items = payload.split(';')
         let uhfCode = ""
         if(items.length>0) uhfCode = items[0]
         this.logs.push({
            date: this.$moment().format("HH:mm:ss"),
            content: "[Ngoài cửa kho] <b>" + uhfCode + "</b> <i>" + this.getNameByQRCode(uhfCode) + "</i>"
         })
         this.trimLog()
      },
   },
   methods: {
      trimLog () {
         this.logs.splice(0, this.logs.length - 100)
      },
      fetchProperties() {
         let self = this;
         self.loading = true;
         self.selected_properties = [];
         let paramsProperties = {
         // created_at_min: moment(self.params.timeRange.start).toISOString(),
         // created_at_max: moment(self.params.timeRange.end).toISOString(),
         // show_deleted: true
         type: 'thiet-bi'
         };
         self.axios
         .get(self.$root.apiAssetMana + "/properties", {
            headers: { "x-auth": this.token },
            params: paramsProperties,
         })
         // + 'owners='+self.$root.site_id
         .then((res) => {
            if (res.data.status == "OK") {
               self.properties = res.data.content.items;
               self.properties = self.properties.filter(i => i.type == 'thiet-bi')
               // console.log(self.properties)
            } else {
               console.log(res.data.message);
            }
         })
         .catch((err) => {
            console.log(err);
         })
         .finally(() => {
            self.loading = false;
         });
      },
      getNameByQRCode (qrCode) {
         let returnData = ""
         let matchProperty = this.properties.find(p => p.qr_code == qrCode)
         if(matchProperty) returnData = matchProperty.name
         return returnData
      }
   },
   created() {
      this.$nextTick(() => {
         this.fetchProperties()
      })
   },
}
</script>

<style>
   
</style>